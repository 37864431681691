// EmailForm.js
import * as React from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs'; // REAL fingerprint library
import { hashComponents } from '@fingerprintjs/fingerprintjs'; // For hashing custom subsets
import CustomButton from './components/CustomButton';
import Fade from '@mui/material/Fade';
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Chip,
  Box,
  Typography,
  Snackbar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InstructionPanel from './components/InstructionPanel';
import LoadingScreen from './components/LoadingScreen';
import ReconsentForm from './components/ReconsentForm';
import deployment_config from './deployment_config.json';
import { Helmet } from 'react-helmet';
import FAQ from './components/faq';
import AnnouncementModal from './components/AnnouncementModal';

import {
  storeUserEmail,
  getUserEmail,
  storeFriendEmails,
  getFriendEmails,
} from './utils';

export default function EmailForm({
  email,
  setEmail,
  setAge,
  setCurrentForm,
  reconsent,
  setReconsent,
  selectedGameMode,
}) {
  const [emailError, setEmailError] = React.useState(false);
  const [friendEmailInput, setFriendEmailInput] = React.useState('');
  const [friendEmails, setFriendEmails] = React.useState([]);
  const [friendEmailError, setFriendEmailError] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [showConsentForm, setShowConsentForm] = React.useState(false);
  const [isBanned, setIsBanned] = React.useState(false);

  // We'll store our custom, stable fingerprint here
  const [fingerprint, setFingerprint] = React.useState('');

  // Snackbars
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [announcementSnackbar, setAnnouncementSnackbar] = React.useState({
    open: false,
    message: '',
  });

  // Announcement modal
  const [showAnnouncement, setShowAnnouncement] = React.useState(true);
  // Instruction panel
  const [instructionOpen, setInstructionOpen] = React.useState(false);

  const apiBaseUrl = deployment_config.apiBaseUrl;

  // Calculate server restart time (daily)
  const serverRestartUTC = new Date();
  serverRestartUTC.setUTCHours(9, 30, 0, 0);
  const localRestartTime = serverRestartUTC.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  const warningMessage = `The server restarts daily at ${localRestartTime}. Avoid joining within 10 mins before that to prevent losing your game state during restarting.`;

  // 1. Retrieve saved emails from local storage
  React.useEffect(() => {
    async function fetchEmails() {
      const storedUserEmail = await getUserEmail();
      const storedFriendEmails = await getFriendEmails();
      if (storedUserEmail) {
        setEmail(storedUserEmail.toLowerCase());
      }
      if (storedFriendEmails && storedFriendEmails.length > 0) {
        const lowercased = storedFriendEmails.map((em) => em.toLowerCase());
        setFriendEmails(lowercased);
      }
    }
    fetchEmails();
  }, [setEmail]);

  // 2. Custom FingerprintJS: Exclude certain components for stability
  React.useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get({ extendedResult: true }))
      .then((result) => {
        /**
         * result.components is an object with keys like:
         *   screenResolution, colorDepth, audio, fonts, canvas, userAgent, timezone, hardwareConcurrency, etc.
         * We'll exclude the ones that are volatile:
         */
        const {
          screenResolution,
          colorDepth,
          audio,
          timezone,
          hardwareConcurrency,
          ...restComponents
        } = result.components;

        // Create a stable hash from the remaining components
        const customVisitorId = hashComponents(restComponents);
        console.log('Custom fingerprint (excluded volatile signals):', customVisitorId);

        setFingerprint(customVisitorId);
      })
      .catch((err) => {
        console.error('FingerprintJS error:', err);
        setFingerprint('failed-fingerprint'); // fallback
      });
  }, []);

  // 3. If user changes their email, remove friend emails that match
  React.useEffect(() => {
    if (email) {
      const filteredFriendEmails = friendEmails.filter((f) => f !== email);
      if (filteredFriendEmails.length !== friendEmails.length) {
        setFriendEmails(filteredFriendEmails);
        setAnnouncementSnackbar({
          open: true,
          message:
            "Some friend emails were removed because they matched your new email.",
        });
      }
      const err = validateFriendEmail(friendEmailInput);
      setFriendEmailError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  // Clear all inputs
  const clear = () => {
    setEmail('');
    setFriendEmailInput('');
    setFriendEmails([]);
    setAge(null);
  };

  // Handle email input
  const handleEmailChange = (e) => {
    const val = e.target.value.toLowerCase();
    setEmail(val);
    setEmailError(!validateEmail(val));
  };

  // Validate email
  const validateEmail = (val) => {
    const re = /^[A-Z0-9._%+\-]+@[A-Z0-9.\-]+\.[A-Z]{2,}$/i;
    return re.test(val);
  };

  // Validate friend email
  const validateFriendEmail = (val) => {
    if (val.trim() === '') return '';
    if (!validateEmail(val)) {
      return 'Invalid email address';
    }
    if (val === email) {
      return "You can't invite yourself!";
    }
    if (friendEmails.includes(val)) {
      return 'Email already added';
    }
    return '';
  };

  // Friend email input change
  const handleFriendEmailInputChange = (e) => {
    const val = e.target.value.toLowerCase();
    setFriendEmailInput(val);
    setFriendEmailError(validateFriendEmail(val));
  };

  // Add friend email
  const handleAddFriendEmail = () => {
    if (friendEmailInput) {
      const err = validateFriendEmail(friendEmailInput);
      if (err) {
        setFriendEmailError(err);
      } else {
        setFriendEmails([...friendEmails, friendEmailInput]);
        setFriendEmailInput('');
        setSnackbarOpen(true);
        setFriendEmailError('');
      }
    }
  };

  // Delete friend email
  const handleDeleteFriendEmail = (del) => {
    setFriendEmails((prev) => prev.filter((em) => em !== del));
  };

  // Press Enter => submit or add friend
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (friendEmailInput && !friendEmailError) {
        handleAddFriendEmail();
      }
      if (
        email &&
        !emailError &&
        !friendEmailError &&
        (selectedGameMode !== 'collaborative' || friendEmails.length > 0)
      ) {
        setLoading(true);
        submitEmail();
      }
    }
  };

  /**
   * MAIN SUBMISSION:
   *  1) Call /ban-list-check with custom fingerprint
   *  2) If 403 => banned
   *  3) If 200 => call /send-token-email
   */
  const submitEmail = () => {
    let updatedFriendEmails = [...friendEmails];
    if (friendEmailInput) {
      const err = validateFriendEmail(friendEmailInput);
      if (err) {
        setFriendEmailError(err);
        setLoading(false);
        return;
      } else {
        updatedFriendEmails.push(friendEmailInput.toLowerCase());
        setFriendEmails(updatedFriendEmails);
        setFriendEmailInput('');
        setSnackbarOpen(true);
        setFriendEmailError('');
      }
    }

    storeUserEmail(email.toLowerCase());
    storeFriendEmails(updatedFriendEmails.map((em) => em.toLowerCase()));

    // Step 1: ban-list-check
    const banCheckPayload = {
      email: email.toLowerCase(),
      deviceFingerprint: fingerprint, // Our custom stable fingerprint
      friendEmails: updatedFriendEmails.map((em) => em.toLowerCase()),
    };

    fetch(`${apiBaseUrl}/ban-list-check`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(banCheckPayload),
    })
      .then((res) => {
        if (res.status === 403) {
          return res.json().then((data) => {
            alert(`You are banned: ${data.ban_reason}`);
            setIsBanned(true);
            throw new Error('UserBanned');
          });
        }
        if (!res.ok) {
          return res.json().then((data) => {
            alert(`Ban check error: ${JSON.stringify(data)}`);
            throw new Error('BanCheckError');
          });
        }
        return res.json();
      })
      .then(() => {
        // Step 2: If not banned => call /send-token-email
        const payload = {
          email: email.toLowerCase(),
          friendEmails: updatedFriendEmails.map((em) => em.toLowerCase()),
          reconsent,
          gameMode: selectedGameMode,
        };
        return fetch(`${apiBaseUrl}/send-token-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 303) {
          response.json().then(() => {
            setCurrentForm('user');
          });
        } else if (response.status === 307) {
          alert('Maintenance is ongoing. Please see Discord for updates!');
          throw new Error('Maintenance');
        } else if (response.status === 308) {
          alert('All Minecraft licenses are occupied, please come back later!');
          throw new Error('NoLicenses');
        } else if (response.status === 305) {
          throw new Error('ConsentRequired');
        } else if (response.status === 304) {
          alert(
            "You're still active on this account. Possibly a recent disconnect. Try a different email or wait a few minutes."
          );
          throw new Error('AlreadyActive');
        } else if (response.status === 403) {
          response.json().then((data) => {
            alert(`You are banned: ${data.ban_reason}`);
            setIsBanned(true);
          });
          throw new Error('UserBanned');
        } else {
          response.json().then((data) => {
            let msg = JSON.stringify(data);
            if (msg.includes('mail')) {
              alert('Issue with your email. Please ensure it is correct.');
            } else if (msg.includes('Error')) {
              alert(msg);
            } else {
              alert('Unknown error occurred. Please report this on Discord.');
            }
          });
          throw new Error('UnknownError');
        }
      })
      .then(() => {
        // Step 3: If successful, go to the token form
        setCurrentForm('token');
      })
      .catch((err) => {
        if (err.message === 'ConsentRequired') {
          setLoading(false);
          setShowConsentForm(true);
        } else if (err.message === 'UserBanned') {
          setLoading(false);
        } else {
          console.error('Error:', err);
          setLoading(false);
        }
      });
  };

  // If user reconsents, automatically resubmit
  React.useEffect(() => {
    if (reconsent && !showConsentForm) {
      submitEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconsent]);

  const isInviteFriendsRequired = selectedGameMode === 'collaborative';

  const instructions = (
    <ol>
      <li>
        Enter your email to get a token to play (Why do we need your email? See <FAQ />).
        You’ll need a new token each time you play.
      </li>
      <li>
        {isInviteFriendsRequired
          ? 'You need to invite at least one friend to proceed.'
          : 'You can invite friends by email if you wish.'}
      </li>
    </ol>
  );

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
    setInstructionOpen(true);
  };

  const videoIframe = (
    <iframe
      width="100%"
      height="266"
      src={`https://www.youtube.com/embed/PdIjQAhQ3Xk?autoplay=${
        instructionOpen ? '1' : '0'
      }`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      allowFullScreen
    ></iframe>
  );

  return (
    <>
      <Helmet>
        <title>Free Minecraft For AI Research</title>
        <meta
          name="description"
          content="Enter your email to get free access to Minecraft and join the community."
        />
      </Helmet>

      {isLoading ? (
        <Fade in={isLoading} timeout={300}>
          <div>
            <LoadingScreen />
          </div>
        </Fade>
      ) : (
        <>
          {showAnnouncement && (
            <AnnouncementModal
              open={showAnnouncement}
              onClose={handleCloseAnnouncement}
            />
          )}
          <form noValidate autoComplete="off" onKeyDown={handleKeyPress}>
            <Grid container direction="column" spacing={2}>
              {/* Warning Box */}
              <Grid item>
                <Box
                  sx={{
                    p: 1,
                    border: '1px solid #FFA726',
                    borderRadius: 1,
                    backgroundColor: '#FFF3E0',
                  }}
                >
                  <Typography variant="body2" color="warning.main" fontWeight="500">
                    {warningMessage}
                  </Typography>
                </Box>
              </Grid>

              {/* Instruction Panel */}
              <Grid item>
                <InstructionPanel
                  instructions={instructions}
                  iframe={videoIframe}
                  autoOpen={instructionOpen}
                />
              </Grid>

              {/* Email Input */}
              <Grid item>
                <TextField
                  error={emailError}
                  helperText={emailError ? 'Invalid email address' : ''}
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  disabled={isBanned}
                />
              </Grid>

              {/* Friend Emails */}
              <Grid item>
                <Box display="flex" alignItems="flex-start">
                  <TextField
                    error={!!friendEmailError}
                    helperText={friendEmailError}
                    label={`Friend Emails ${
                      isInviteFriendsRequired ? '' : '(optional)'
                    }`}
                    variant="outlined"
                    fullWidth
                    value={friendEmailInput}
                    onChange={handleFriendEmailInputChange}
                    disabled={isBanned}
                  />
                  <IconButton
                    color="primary"
                    onClick={handleAddFriendEmail}
                    disabled={!friendEmailInput || !!friendEmailError || isBanned}
                    aria-label="add friend email"
                    sx={{ mt: 1 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                {friendEmails.length > 0 && (
                  <Box mt={1}>
                    <Typography variant="subtitle2">
                      Friends you're inviting:
                    </Typography>
                    <Box display="flex" flexWrap="wrap" mt={0.5}>
                      {friendEmails.map((em, index) => (
                        <Chip
                          key={index}
                          label={em}
                          onDelete={() => handleDeleteFriendEmail(em)}
                          deleteIcon={<DeleteIcon />}
                          sx={{ m: 0.25 }}
                          disabled={isBanned}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>

              {/* Require friends if collaborative */}
              {isInviteFriendsRequired && friendEmails.length === 0 && (
                <Grid item>
                  <Typography variant="caption" color="error">
                    You must invite at least one friend to proceed.
                  </Typography>
                </Grid>
              )}

              {/* Submit / Clear Buttons */}
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                spacing={0}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item>
                  <CustomButton
                    isDisabled={
                      !email ||
                      emailError ||
                      (!!friendEmailError && friendEmailInput !== '') ||
                      (isInviteFriendsRequired && friendEmails.length === 0) ||
                      isBanned
                    }
                    content="Submit"
                    color="success"
                    onClick={() => {
                      setLoading(true);
                      submitEmail();
                    }}
                  />
                </Grid>
                <Grid item ml={2}>
                  <CustomButton
                    isDisabled={isBanned}
                    content="Clear"
                    color="primary"
                    onClick={clear}
                  />
                </Grid>
              </Grid>

              {/* Reconsent Form */}
              {showConsentForm && (
                <Grid item>
                  <ReconsentForm
                    consent={reconsent}
                    setConsent={(value) => {
                      setReconsent(value);
                      setShowConsentForm(false);
                      setLoading(true);
                    }}
                  />
                  {!reconsent && (
                    <Button color="error">
                      Consent terms have been updated! Approval is required.
                    </Button>
                  )}
                  {reconsent && <Button color="success">Consent Granted</Button>}
                </Grid>
              )}
            </Grid>
          </form>
        </>
      )}

      {/* Friend Email Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        message="Friend email added!"
      />

      {/* Announcement Snackbar */}
      <Snackbar
        open={announcementSnackbar.open}
        autoHideDuration={6000}
        onClose={() => setAnnouncementSnackbar({ open: false, message: '' })}
        message={announcementSnackbar.message}
      />
    </>
  );
}
