import React from 'react';
import { Box, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function FAQ() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <span>
            <Typography
                component="span"
                variant="body1"
                onClick={handleOpen}
                sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { textDecoration: 'underline' } }}
            >
                FAQ
            </Typography>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        padding: '50px',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Frequently Asked Questions
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <b>Q: What is this all about?</b><br />
                        A: PLAICraft is a research data collection project with the aim of enabling advanced embodied AI research.<br /><br />

                        <b>Q: How many people have participated and how much data do you have?</b><br />
                        A: As of November 2024 over one thousand participants have played over two thousand hours of Minecraft.<br /><br />

                        <b>Q: What kind of Minecraft server is this?</b><br />
                        A: PLAICraft is a vanilla survival Minecraft server with amazing shaders and, for obvious reasons, spatial audio plugin support.<br /><br />

                        <b>Q: How many people are generally on the server?</b><br />
                        A: There are usually between one and twenty people playing at a time.  There are amazing communities spread across a huge world.  Please come and make a new one of your own!<br /><br />

                        <b>Q: My gameplay is very laggy, how to fix?</b><br />
                        A: The fps you have is highly affected by your connection latency and speed. We run the actual game in powerful virtual machines to achieve a steady 60fps. Your device only handles the streaming part. So get a better internet connection will be a good start. <br /><br />

                        <b>Q: Can I turn off the shaders? Can I change the settings?</b><br />
                        A: No. We need to keep the visuals and actions the same for data consistency.<br /><br />

                        <b>Q: Why do you need my email?</b><br />
                        A: We need your email to send you access tokens that allow you to play Minecraft.  
                        We also need your email to make sure that we can identify and delete your data if you wish for us to do so.  We also use your email to communicate 
                        with you about consent and other issues.  We will never divulge, sell, or otherwise use or abuse our access to your email.  It is forbidden for us 
                        to do so under by the requirements to optain institutional approval we are subject to.  We aren't bad people either so we wouldn't do it anyway.<br /><br />

                        <b>Q: Is this real?  Can we trust you?</b><br />
                        A: The University of British Columbia office of reserarch ethics has granted institutional approval for us to run this study.  For this reason, formal consent is required to participate.  The consent form is <a href="files/ConsentForm_raffle.pdf" target="_blank" rel="noopener noreferrer">here</a> and the first time you sign-up we need your assent or consent from your parent/guardian if you're young in order to participate.   <br /><br />

                        <b>Q: Do I get anything for particating?</b><br />
                        A: You get to play Minecraft on an awesome server for free.  Additionally, if eligible, you may be automatically entered into a sweepstakes to win an Amazon gift card as a way of thanking  you for participating.  The contest rules are <a href="files/Contest_rule.pdf" target="_blank" rel="noopener noreferrer">here</a>  <br /><br />
                       
                        <b>Q: I want to play with my friends?  How do I do this?</b><br />
                        A: As part of our research, we explicitly want to encourage people to play and interact with people whom they do not already know. This is to ensure our AI model is as diverse as possible. However, if you know the in-game participant name of one of your friends you can always teleport to them once on the server.  The command to do this on the server is <code>/tpa</code><br /><br />.
                        
                        <b>Q: I am new to Minecraft.  Is this OK?</b><br />
                        A: This is the ideal situation!  We want you to join and play.  And we really want you to learn from other players by asking them questions.  See the next question though.<br /><br />.

                        <b>Q: Is there anything I should know about this server?</b><br />
                        A: PLAICraft is a survival server.  And, for technical reasons, you may join the world at night.  For players both new and old, this can
                        be a very big challenge.  Take cover and do your very best to survive the night.  If you are new to Minecraft you are very likely to die, repeatedly, at night.  Dig down.  Take cover.  Run from mobs.  Stay alive.<br /><br />.

                        <b>Q: What is embodied AI?</b><br />
                        A: Embodied AI is about having a body and interacting with a world in a natural way.  Here this specifically means the Minecraft world and interactions that include speech.<br /><br />

                        <b>Q: What do you record?</b><br />
                        A: We record your speech and key presses and key presses, however, only in the PLAICraft Minecraft brower windnow.<br /><br />

                        <b>Q: What makes this different to  other Minecraft or game-based AI research projects?</b><br />
                        A: No other such project records spoken interactions between players.  And no other Minecraft AI research project fully embraces the 
                        complete open-world Minecraft environment.<br /><br />

                        <b>Q: What do you hope to achieve with this research?</b><br />
                        A: We hope to create architectures and learning algorithms for emobodied AI agents that can be employed not only as NPCs in Minecraft (how cool would that be?!?) 
                        but also in other domains.  The data we collect here is reallly only ever going to be useful for Minecraft NPC development, however, the open-world nature of 
                        Minecraft allows us to ask all kinds of interesting questions like: "What does a person playing Minecraft actually want to accomplish?"  and "How can we tell if 
                        an agent is truly intelligent?"  and "How can we make an agent that learns while acting?"<br /><br />

                        <b>Q: Is this a safe environment for me or my children?</b><br />
                        A: We can make no strict guarantee that bad people won't do or say bad things on the research platform, however, we do monitor and police 
                        behavior and speech, blocking contributors who behave badly.  That said, the fact that we record everything that is said has led to an overwhelmingly
                        positive and encouraging community.<br /><br />

                        <b>Q: Do I have to talk?</b><br />
                        A: Talking with other players is required unless playing alone.  If we detect that you are playing without talking to other players
                        we are likely to ban you from the server.<br /><br />

                        <b>Q: What happens if I accidentally close my PLAICraft browser tab?  Can I get back in?</b><br />
                        A: You will be able to rejoin the game where you left off, however, you will have to go through the process of getting a login token again unfortunately.<br /><br />

                        <b>Q: I can't move using WASD and look around at the same time. I'm using a trackpad.</b><br />
                        A: We've had problems playing with trackpad before. Try using an external mouse to see if it resolves your issue.<br /><br />

                        <b>Q: I'm looking down and spinning.  Nothing works.  WTF?</b><br />
                        A: You need to click on the middle of the screen once or twice to go fullscreen.  The mouse will work as expected when in full screen mode.<br /><br />

                        <b>Q: How to report other problems I have?</b><br />
                        A: Join our discord by clicking on the discord icon on the main page.<br /><br />

                        <b>Q: I want my data not to be used in the study. How do I get it deleted?</b><br />
                        A: Please join our discord first, you can find the invite link on the main page discord icon, then provide us the token used to initiate the game session you would like to have deleted.<br /><br />

                        <b>Q: I experienced abusive interactions during my PLAICraft experience, where do I report it?</b><br />
                        A: Please join our discord first, you can find the invite link on the main page discord icon, then provide us the token used to initiate the game session.  We will make best effort attempts to identify the perpetrators and ban them from contributing.<br /><br />

                        <b>Q: What exactly do you record and how?</b><br />
                        A: We record what you see in the full-screen PLAICraft browser window, the keystrokes and mouse actions you make only in the PLAICraft browser window, what you say via your microphone, and in-game audio. The software we use to accomplish these tasks is installed on our servers in the cloud; there is zero software installed on your computer. <br /><br />

                    </Typography>
                </Box>
            </Modal>
        </span>
    );
}
