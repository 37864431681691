import React, { useState } from 'react';
import { Container, Box, Link, Typography, Modal } from '@mui/material';
import FAQ from './faq';
import UnsubscribeForm from './Unsubscribe';
import ContestRules from './ContestRules';

export default function Footer() {
    const [openUnsubscribe, setOpenUnsubscribe] = useState(false);

    const handleOpenUnsubscribe = () => {
        setOpenUnsubscribe(true);
    };

    const handleCloseUnsubscribe = () => {
        setOpenUnsubscribe(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <footer>
            <Container maxWidth="md" sx={{ mt: 3 }}>
                <Box sx={{ textAlign: 'center', py: 3 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                        <Link href="https://plai.cs.ubc.ca/" target="_blank" underline="none" sx={{ p: 1 }}>
                            <img src="https://plai.cs.ubc.ca/files/2019/12/PLAIRGB-e1582067341488.png" alt="" width="85" />
                        </Link>
                        <Link href="https://ubc.ca" target="_blank" underline="none" sx={{ p: 1 }}>
                            <img src="https://logos-download.com/wp-content/uploads/2016/10/UBC_logo.png" alt="" width="50" />
                        </Link>
                        <Link href="https://discord.gg/g6aSCqyEm8" target="_blank" underline="none" sx={{ p: 1 }}>
                            <img src="https://img.icons8.com/?size=100&id=114902&format=png&color=000000" alt="" width="80" />
                        </Link>
                        <Link href="https://blog.plaicraft.ai/" target="_blank" underline="none" sx={{ p: 1 }}>
                            <img src="/icons/blog-icon-blue-sparkle.png" alt="" width="80" />
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 2, mb: 1 }}>
                        <Link
                            href="https://discord.gg/g6aSCqyEm8"
                            target="_blank"
                            sx={{ mx: 1, mb: 1 }}
                            underline="hover"
                            color="textSecondary"
                        >
                            Support
                        </Link>
                        <Box sx={{ mx: 1, mb: 1 }}>
                            <FAQ />
                        </Box>
                        <Box sx={{ mx: 1, mb: 1 }}>
                            <ContestRules />
                        </Box>
                        <Link
                            sx={{ mx: 1, mb: 1, cursor: 'pointer' }}
                            underline="hover"
                            color="textSecondary"
                            onClick={handleOpenUnsubscribe}
                        >
                            Unsubscribe
                        </Link>
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                        © 2024 Pacific Laboratory of Artificial Intelligence. <br /> We are NOT affiliated with Mojang Studios or Microsoft.
                    </Typography>
                </Box>
            </Container>
            <Modal
                open={openUnsubscribe}
                onClose={handleCloseUnsubscribe}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <UnsubscribeForm open={openUnsubscribe} onClose={handleCloseUnsubscribe} />
                </Box>
            </Modal>
        </footer>
    );
}
